<template>
  <div>
    <h4>Type Evenement</h4>
      
    <data-table
      :data-source="types"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <a
          href="javascript:void(0)"
          class="btn btn-outline-primary btn-icon"
          data-toggle="modal"
          data-target="#add-type-event"
        >
          <i class="icofont icofont-plus" />
        </a>
      </template>
    </data-table>
          
    <modal
      title="Ajout du type d'evenement"
      id="add-type-event"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="adding"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="add-libelle">Libelle</label>
          <input
            id="add-libelle"
            name="add-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="form-group">
          <label for="add-code">Code</label>
          <input
            id="add-code"
            name="add-code"
            type="text"
            class="form-control"
            v-model="code"
          >
        </div>
        <div class="form-group">
          <label for="add-label">Label</label>
          <select 
            id="add-label"
            name="add-label"
            v-model="label"
            class="form-control" 
            v-select="{placeholder: 'Selectrioner le label'}"
          >
            <option />
            <option
              v-for="(item, index) in labelList"
              :key="index"
              :value="item.type"
            >
              {{ item.libelle }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="add-description">Description</label>
          <textarea
            class="form-control"
            id="add-description"
            name="add-description"
            v-model="description"
            rows="4"
          />
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Ajouter
          </button>
        </div>
      </form>
    </modal>
  
    <modal
      title="Modification du type d'evenement"
      id="update-type-event"
      :footer="false"
    >
      <form
        class="form-horizontal"
        @submit.prevent="editing"
      >
        <div
          class="alert alert-danger"
          v-if="has_error"
        >
          {{ error_msg }}
        </div>
        <div class="form-group">
          <label for="update-libelle">Libelle</label>
          <input
            id="update-libelle"
            name="update-libelle"
            type="text"
            class="form-control"
            v-model="libelle"
          >
        </div>
        <div class="form-group">
          <label for="update-code">Code</label>
          <input
            id="update-code"
            name="update-code"
            type="text"
            class="form-control"
            v-model="code"
          >
        </div>
        <div class="form-group">
          <label for="update-label">Label</label>
          <select 
            id="update-label"
            name="update-label"
            v-model="label"
            class="form-control" 
            v-select="{placeholder: 'Selectrioner le label'}"
          >
            <option />
            <option
              v-for="(item, index) in labelList"
              :key="index"
              :value="item.type"
            >
              {{ item.libelle }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="update-description">Description</label>
          <textarea
            class="form-control"
            id="update-description"
            name="update-description"
            v-model="description"
            rows="4"
          />
        </div>
        <div class="form-group text-right">
          <button
            type="submit"
            class="btn btn-primary"
          >
            Enregistrer
          </button>
        </div>
      </form>
    </modal>
  </div>
</template>
  
  <script>
  import {mapGetters, mapMutations} from 'vuex'
  import DataTable from '../../../components/dataTable/local.vue'
  import { COMPONENT_TYPE, TEXT_TYPE } from '../../../components/dataTable/dataType'
  import Modal from '../../../components/modal.vue'
import { ADD_TYPE_EVENT, UPDATE_TYPE_EVENT } from '../../../graphql/events'
import { Select } from '../../../directive/select'
import { LIST_OF_LABEL } from '../../../constants/events'
  const Actions = () => import('../../../components/admin/events/typeAction.vue')
  export default {
      components: { DataTable, Modal},
      directives: {Select},
      data(){
          return {
              libelle: null,
              label: null,
              code: null,
              description: null,
              has_error: false,
              error_msg: null
          }
      },
      watch: {
          selectedType: {
              handler(){
                  if(this.selectedType !== null) {
                      this.libelle = this.selectedType.libelle
                      this.label = this.selectedType.label
                      this.description =  this.selectedType.description
                      this.code = this.selectedType.code
                  }
              },
              deep: true
          }
      },
      methods: {
          ...mapMutations({
              done: 'DONE',
              setSelectedObject: 'SET_SELECTED_OBJECT'
          }),
          initForm(){
              this.setSelectedObject(null)
              this.libelle = null
              this.label = null
              this.description = null
              this.code = null
              this.has_error = false
              this.error_msg = null
          },
          adding(){
              let data = {
                  libelle: this.libelle,
                  label: this.label,
                  description: this.description,
                  code: this.code
              }
              this.$apollo.mutate({
                  mutation: ADD_TYPE_EVENT,
                  variables: {
                      "type": {
                          ...data
                      }
                  },
                  update: (d) => {
                      console.log(d)
                      console.log(`Type event ${this.libelle} add successfully`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          },
          editing(){
              let data = {
                  libelle: this.libelle,
                  label: this.label,
                  description: this.description,
                  code: this.code
              }
              this.$apollo.mutate({
                  mutation: UPDATE_TYPE_EVENT,
                  variables: {
                      "type": {
                          ...data
                      },
                      "uid": this.selectedType.uid
                  },
                  update: (d) => {
                      console.log(d)
                      console.log(`Type event ${this.libelle} on ${this.selectedType.uid} updated successfully`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          }
      },
      computed: {
          ...mapGetters({
              types: 'events/types',
              selectedType: 'selectedObject'
          }),
          labelList(){
            return LIST_OF_LABEL()
          },
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE},
                  {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                  {label: 'Code', name: 'code', type: TEXT_TYPE},
                  {label: 'Description', name: 'description', type: TEXT_TYPE},
                  {label: 'Label', name: 'label', type: TEXT_TYPE},
                  {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions}
              ]
          }
      }
  }
  </script>
  
  <style>
  
  </style>